import React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";
import Slider from "infinite-react-carousel";
import Card from "./cards/index";
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Seguros({
  segurobici,
  seguromotos,
  segurohogar,
  seguromascotas,
  seguroauto,
  segurocomercio,
  marcas,
}) {
  const { height, width } = useWindowDimensions();

  return (
    <Container marcas={marcas}>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag Container */}
        <Fade delay={100} duration={800} className="tag-container">
          {/* Tag */}
          <div>
            <p>Nuestros seguros</p>
          </div>
        </Fade>
        {/* Title Container */}
        <Fade delay={100} duration={800} className="title-container">
          {/* Title */}
          <h3>
            ¡<span>Cotizá ahora</span> de manera inmediata!
          </h3>
        </Fade>
        {/* Carousel */}
        <Slider
          arrows={false}
          arrowsBlock={false}
          autoplay={true}
          dots={false}
          duration={150}
          slidesToShow={
            width > 1710 ? 4 : width > 1200 ? 3 : width > 850 ? 2 : 1
          }
          wheel={false}
          className="carousel"
        >
          {/* SeguroBici */}
          {segurobici != false && (
            <Card
              image="/logos/gys/segurobici.svg"
              alt="Logo de SeguroBici"
              title="Estas muy cerca de asegurar tu bici, sentite libre."
              text="Bicicleta, bicicleta comercial, monopatín, monopatín eléctrico."
              link="https://www.segurobici.com.ar/"
            />
          )}
          {/* SeguroMotos */}
          {seguromotos != false && (
            <Card
              image="/logos/gys/seguromotos.svg"
              alt="Logo de SeguroMotos"
              title="Ella te acompaña a donde vayas, vos elegí el camino que los une."
              text="Moto, moto comercial, scooter, cuatriciclo, ciclomotor."
              link="https://seguromotos.com.ar/"
            />
          )}
          {/* SeguroHogar */}
          {segurohogar != false && (
            <Card
              image="/logos/gys/segurohogar.svg"
              alt="Logo de SeguroHogar"
              title="Asegurá tu hogar y obtené tu póliza al instante."
              text="Todo tipo de viviendas en todo el país."
              link="https://www.segurohogar.com.ar/"
            />
          )}
          {/* SeguroMascotas */}
          {seguromascotas != false && (
            <Card
              image="/logos/gys/seguromascotas.svg"
              alt="Logo de SeguroMascotas"
              title="Sabemos lo importante que son para vos, cuidalos con nosotros."
              text="Perros y gatos de todas las razas."
              link="https://www.seguromascotas.com.ar/"
            />
          )}
          {/* SeguroAuto */}
          {seguroauto != false && (
            <Card
              image="/logos/gys/SeguroAuto.svg"
              alt="Logo de SeguroAuto"
              title="Asegurá tu vehículo y manejá de la mejor manera."
              text="Todos los modelos de autos, camiones y pickups."
              link="https://auto.seguroweb.com.ar/"
            />
          )}
          {/* SeguroComercio */}
          {segurocomercio != false && (
            <Card
              image="/logos/gys/segurocomercio.svg"
              alt="Logo de SeguroComercio"
              title="Contratá Federación Patronal, número 1 en solvencia financiera."
              text="Todo tipo de comercios en todo el país."
              link="https://comercio.seguroweb.com.ar/"
            />
          )}
        </Slider>
      </section>
    </Container>
  );
}
