import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header/index";
import Titulo from "../components/titulo-institucionales";
import Switch from "../components/referidos/switch";
import Footer from "../components/footer/index";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function ReferidosContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <Titulo
        tituloSpan="Referidos"
        titulo=""
        subtitulo="¡Referí a tus amigos!"
        tag="Promo"
      />
      {/* Formulario */}
      <Switch></Switch>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
