import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header/index";
import Titulo from "../components/titulo-institucionales";
import Switch from "../components/forma-parte/switch";
import Footer from "../components/footer/index";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function FormaParteContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <Titulo
        tituloSpan="Formá"
        titulo=" parte"
        subtitulo="Comunicate con nosotros"
        tag="Recursos humanos"
      />
      {/* Formulario */}
      <Switch></Switch>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
