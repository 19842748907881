export const faqs = [
    {
        id: 1,
        pregunta: "¿Qué mascotas aseguran?",
        respuesta: <p itemprop="text">Aseguramos solamente perros y gatos de cualquier raza o
        mestizos.</p>
    },
    {
        id: 2,
        pregunta: "¿Si mi mascota no es de raza la puedo asegurar igual?",
        respuesta: <p itemprop="text">¡Si! Aseguramos mascotas de cualquier raza y mestizos.</p>
    },
    {
        id: 3,
        pregunta: "¿Cómo hago para contratar el seguro?",
        respuesta: <p itemprop="text">¡Es muy simple! Cotizá con tus datos en nuestra página y un
        asesor se va a contactar con vos para brindarte la mejor
        cobertura para tu mascota.</p>
    },
    {
        id: 4,
        pregunta: "¿Qué necesito para contratar el seguro?",
        respuesta: <p itemprop="text">Si tu mascota es de raza, el certificado de inscripción o
        recibo de compra. Si tu mascota es mestizo o adoptado, un
        certificado veterinario que indique la edad.</p>
    },
    {
        id: 5,
        pregunta: "¿Puedo asegurar más de una mascota?",
        respuesta: <p itemprop="text">Si tenés más de una mascota, podés sacar un seguro para cada
        una.</p>
    },
    {
        id: 6,
        pregunta: "¿Qué hago al momento de un siniestro?",
        respuesta: <p itemprop="text">Contactanos al 0810-220-2373, por WhatsApp al 11-2806-1540, o
        mandános un mail a siniestros@seguroweb.com.ar.</p>
    }
];
  