import { useState, useEffect, useRef } from "react";
import axios from "axios";

export const useCotizador = (inputValues) => {
  const [ip, setIp] = useState("");
  const [canal_url, setCanal_url] = useState("");
  // Estado para el textarea de raza
  const [razaOpen, setRazaOpen] = useState(false);

  // Refs
  const referencia = useRef(null);
  const textareaRaza = useRef(null);


  // Obtiene la IP del usuario
  useEffect(() => {
    const data = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
      // Obtiene la ruta desde la que se ingresá (canales)
      const url = window.location.toString().split("/");
      const sufijo = url[url.length - 1];
      let canal;
      switch (sufijo) {
        case "gs":
          canal = 1;
          break;
        case "gd":
          canal = 2;
          break;
        case "in":
          canal = 38;
          break;
        case "fb":
          canal = 7;
          break;
        case "tiktok":
          canal = 135;
          break;
        case "twitch":
          canal = 141;
          break;
        default:
          canal = 24;
          break;
      }
      setCanal_url(canal);
    };
    data();
  }, []);

  // Toogle Raza
  const toggleRaza = () => {
    setRazaOpen(true);
  };

  // Hace que si se hace click afuera del textarea de raza se desactiva
  useEffect(() => {
    function handleClickOutside(event) {
      if (referencia.current && !referencia.current.contains(event.target)) {
        setRazaOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [referencia]);

  return {
    ip,
    canal_url,
    razaOpen,
    toggleRaza,
    referencia,
    textareaRaza
  };
};
