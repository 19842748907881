import { useEffect, useState } from "react";
import { actualizarValoresCotizacion, obtenerCotizacion, enviarMailCotizacion } from "../services/cotizaciones";
import { useLocation } from "react-router-dom";

export const usePlanes = () => {

  const location = useLocation();
  const id_operacion = new URLSearchParams(location.search).get("op");
  const hash = new URLSearchParams(location.search).get("hash");

  const locationPlanes = location?.state?.planes ? location.state.planes : []

  const [planes, setPlanes] = useState(locationPlanes)
  const [cotizacion, setCotizacion] = useState(location?.state ? location.state : {})
  const [planSelected, setPlanSelected] = useState(null)
  const [planesCotizados, setPlanesCotizados] = useState(false)

  const planesFromDb = async () => {
    try {
      const dataOp = await obtenerCotizacion(id_operacion)
      if (dataOp.status === 200) {
        setPlanes(dataOp.data.planes);
        setCotizacion(dataOp.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const planesAPI = async () => {
    try {
      if (planesCotizados) return;
      actualizarPlanes(true)
      await actualizarValoresCotizacion(id_operacion, planes)
      await enviarMailCotizacion(id_operacion)
    } catch (error) {
      console.log(error);
    }
  }

  const handlePlan = (plan) => {
    setPlanSelected(plan)
  }

  const actualizarPlanes = (planes) => {
    setPlanesCotizados(planes)
  }

  useEffect(() => {
    if (hash) {
      planesFromDb()
    } else {
      planesAPI()
    }
  }, [])

  return {
    planes, 
    cotizacion, 
    handlePlan,
    planSelected
  }
};
