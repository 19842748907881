import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header/index";
import Cotizador from "../components/cotizador";
import Logos from "../components/inicio/logos";
import Cubrimos from "../components/inicio/cubrimos";
import Pasos from "../components/inicio/pasos";
import Referidos from "../components/inicio/referidos";
import Beneficios from "../components/inicio/beneficios";
import Contacto from "../components/inicio/contacto";
import Faqs from "../components/inicio/faqs";
import Footer from "../components/footer";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function InicioContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador
        landing="federacion"
        isReferidosHeaderOpen={isReferidosHeaderOpen}
      ></Cotizador>
      {/* Logos */}
      <Logos landing="federacion"></Logos>
      {/* Cubrimos */}
      <Cubrimos></Cubrimos>
      {/* Pasos */}
      <Pasos></Pasos>
      {/* Referidos */}
      <Referidos></Referidos>
      {/* Beneficios */}
      <Beneficios></Beneficios>
      {/* Contacto */}
      <Contacto></Contacto>
      {/* Faqs */}
      <Faqs></Faqs>
      {/* Footer */}
      <Footer marcas="active"></Footer>
    </Container>
  );
}
