import { useState } from "react";
import useValidation from "./useValidation";
import { fieldsExcludedFromStorage } from "../constants/form";

export const useForm = (submitCallback, rules, initialValues) => {
  const [inputValues, setInputValues] = useState(initialValues);
  const { validateForm, formErrors } = useValidation(rules(inputValues));

  const resetFieldsValues = (inputName) => {
    const keys = Object.keys(inputValues)
    const currentInputIndex = keys.findIndex(key => key === inputName)
    const keysAfterCurrentInput = keys.slice(currentInputIndex + 1, keys.length)
    keysAfterCurrentInput.forEach(key => {
      setInputValues((prev) => ({ ...prev, [key]: '' }))
      localStorage.setItem(`${key}-seguromascotas`, "");
    })
  }

  const handleChange = (e, resetFields) => {
    const { name, value } = e.target
    setInputValues((prev) => ({ ...prev, [name]: value }))
    if(!fieldsExcludedFromStorage.includes(name)){
      localStorage.setItem([name] + "-seguromascotas", value)
    }
    if(resetFields){
      resetFieldsValues(name)
    }
  };

  // Para elementos del form que no poseen atributo name (captcha)
  const handleChangeExternal = (fieldName, fieldValue) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
  }

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target
    setInputValues((prev) => ({ ...prev, [name]: checked }))
  }

  // Validar Formulario
  const handleValidation = (e) => {
    e.preventDefault();
    const formValidation = validateForm();
    if (Object.keys(formValidation).length === 0) 
      submitCallback()
  };

  return {
    inputValues,
    handleChange,
    handleValidation,
    handleChangeCheckbox,
    formErrors,
    handleChangeExternal
  };
};
