import { useState } from "react";
import Marcador from "../marcador/index";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";
import Container from "./styled";
import NumberFormat from "react-number-format";
import Tarjeta from "./tarjeta";
import { Fade } from "react-awesome-reveal";
import "material-symbols";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useLoading } from "../../../hooks/useLoading";
import { useScrollTop } from "../../../hooks/useScrollTop";
// Form
import { contrataForm } from "../../../utils/formInitialValues";
import { contrataReglas } from "../../../utils/formRules";
import { postearContratacion } from "../../../services/contratacion";

export default function Contrata({
  prevStep,
  nextStep,
  planSelected,
  cotizacion,
}) {
  const fecha = new Date();
  const obtenerMesActual = fecha.getMonth() + 1;
  const mesActual =
    obtenerMesActual.toString().length < 2
      ? "0" + obtenerMesActual
      : obtenerMesActual;
  const añoActual = fecha.getFullYear();

  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    contrataReglas,
    contrataForm
  );
  const { loading, initiateLoading } = useLoading();
  useScrollTop();

  async function handleSubmit() {
    initiateLoading();
    const pago = {
      nombre: `${inputValues.nombre} ${inputValues.apellido}`,
      telefono: `(${inputValues.codigoArea}) ${inputValues.telefono}`,
      dni: inputValues.cuitDNI,
      email: inputValues.email,
      numOp: cotizacion.numero,
      producto: cotizacion.producto,
      plan: planSelected.plan,
      compania: planSelected.nombre_compania,
      valor_contratado: planSelected.precio,
      formaDePago: inputValues.formaDePago,
      tipo_tarjeta: inputValues.tipo_tarjeta,
      name: inputValues.titular,
      number: inputValues.numeroTarjeta,
      cvc: inputValues.cvc,
      expiry: inputValues.vencimiento,
      cbu: inputValues.cbu,
      dniTarjeta: inputValues.dniTitular,
      banco_emisor: inputValues.bancoEmisor,
    };

    try {
      const contratacion = await postearContratacion(pago);
      if (contratacion.status === 200) {
        nextStep();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno="hecho"
        pasoDos="hecho"
        pasoTres="haciendo"
        pasosTerminados=""
        titulo="¡Ya elegiste tu seguro!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad02">
        {/* Botón Atras */}
        <button type="button" onClick={prevStep}>
          <span>
            <BsArrowLeft />
          </span>
          Volvé a elegir
        </button>
      </div>
      {/* Max Width Coberturas Container */}
      <div id="max-width-coberturas-container">
        <p className="titulo-section">
          ¡Bien! ¡Elegiste el plan ideal para vos!
        </p>
        {/* Coberturas Container */}
        <div className="coberturas-container opacidad02">
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img alt="Logo Seguro" src={planSelected.logo}></img>
            <p>{planSelected.plan}</p>
          </div>
          {/* Checks Container */}
          <div className="checks-container">
            {planSelected.coberturas.map((cobertura) => {
              return (
                <span id={cobertura.id} className="check">
                  <p>{cobertura.cobertura_info.nombre}</p>
                  {cobertura.activo ? (
                    <span class="material-symbols-outlined">done</span>
                  ) : (
                    <span id="check-no" class="material-symbols-outlined">
                      close
                    </span>
                  )}
                </span>
              );
            })}
          </div>
          {/* Información Vehiculo */}
          <div
            className="informacion-vehiculo"
            id="informacion-vehiculo-mobile"
          >
            <p>Golden Retriver</p>
            <p>10 años</p>
            <img
              src="/pasos/contrata/escudo.svg"
              alt="Escudo de SeguroMotos"
            ></img>
          </div>
          {/* Precios Container
              Aca cambiar una vez se reciba la data
              <div className="precio-container">
                Cuotas Container
                <div>
                  {this.props.planSelected.cuotas == "" ? null : (
                    <p
                      id={this.props.planSelected.precio == "" && "cuotas-bold"}
                      className={
                        this.props.planSelected.precioAnterior == "" &&
                        "cuotas-naranja"
                      }
                    >
                      {this.props.planSelected.cuotas}
                    </p>
                  )}
                  {this.props.planSelected.precioAnterior == "" ? null : (
                    <p id="precio-anterior">
                      {this.props.planSelected.precioAnterior}
                    </p>
                  )}
                </div>
                Precio
                {this.props.planSelected.precio == "" ? null : (
                  <div>
                    {this.props.planSelected.oferta == "" ? null : (
                      <p>{this.props.planSelected.oferta}</p>
                    )}
                    <p>{this.props.planSelected.precio}</p>
                  </div>
                )}
                {this.props.planSelected.contratando == "" ? null : (
                  <div>
                    <p>{this.props.planSelected.contratando}</p>
                  </div>
                )}
              </div> */}
          {/* Precios Container */}
          <div className="precio-container">
            <div>
              <p id="cuotas-bold">Cuotas de</p>
              <p id="precio-anterior"></p>
            </div>
            <div>
              <p>${planSelected.precio}</p>
            </div>
            <div>
              <p>Contratando Online</p>
            </div>
          </div>
        </div>
        {/* Datos Container */}
        <div id="datos-container">
          {/* Datos Mascota */}
          <div className="datos-vehiculo">
            {cotizacion.mascota == "Perro" ? (
              <img alt="Ícono de perro" src="/pasos/contrata/perro.svg"></img>
            ) : (
              <img alt="Ícono de gato" src="/pasos/contrata/gato.svg"></img>
            )}
            <p>{cotizacion.mascota}</p>
          </div>
          {/* Información Vehiculo */}
          <div className="informacion-vehiculo">
            <p>{cotizacion.raza}</p>
            <p>{cotizacion.edad} años</p>
            <img
              src="/pasos/contrata/escudo.svg"
              alt="Escudo de SeguroMascotas"
            ></img>
          </div>
          {/* Datos Cotizacion */}
          <div id="datos-cotizacion">
            {/* Plan Seleccionado */}
            <div className="dato">
              <p>Provincia</p>
              <p>{cotizacion.provincia}</p>
            </div>
            {/* Plan Seleccionado */}
            <div className="dato">
              <p>{planSelected.nombre_compania}</p>
              <p>Plan {planSelected.plan}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Formulario */}
      <form className="opacidad05">
        {/* Datos Personales Container */}
        <div className="datos-personales-container">
          <p className="titulo-formulario">
            Completá este pequeño formulario con tus datos
          </p>
          {/* Input Nombre */}
          <div className="input-container" id="input-nombre">
            <input
              type="text"
              id="inputNombre"
              name="nombre"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.nombre}
              className={formErrors.nombre == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.nombre == null || inputValues.nombre == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputNombre"
            >
              Nombre
            </label>
            {formErrors.nombre && <span>{formErrors.nombre}</span>}
          </div>
          {/* Input Apellido */}
          <div className="input-container" id="input-apellido">
            <input
              type="text"
              id="inputApellido"
              name="apellido"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.apellido}
              className={formErrors.apellido == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.apellido == null || inputValues.apellido == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputApellido"
            >
              Apellido
            </label>
            {formErrors.apellido && <span>{formErrors.apellido}</span>}
          </div>
          {/* Input CUIT o DNI */}
          <div className="input-container" id="input-cuit-dni">
            <input
              type="number"
              id="inputCUITDNI"
              name="cuitDNI"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.cuitDNI}
              className={formErrors.cuitDNI == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.cuitDNI == null || inputValues.cuitDNI == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputCUITDNI"
            >
              CUIT o DNI
            </label>
            {formErrors.cuitDNI && <span>{formErrors.cuitDNI}</span>}
          </div>
          {/* Input Email */}
          <div className="input-container" id="input-email">
            <input
              type="email"
              id="inputEmail"
              name="email"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.email}
              className={formErrors.email == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.email == null || inputValues.email == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputEmail"
            >
              Email
            </label>
            {formErrors.email && <span>{formErrors.email}</span>}
          </div>
          {/* Input Cód. Área */}
          <div className="input-container" id="input-codigo-area">
            <input
              type="number"
              id="inputCodigoArea"
              name="codigoArea"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.codigoArea}
              className={formErrors.codigoArea == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.codigoArea == null || inputValues.codigoArea == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputCodigoArea"
            >
              Cód. Área
            </label>
            {formErrors.codigoArea && <span>{formErrors.codigoArea}</span>}
          </div>
          {/* Input Teléfono */}
          <div className="input-container" id="input-telefono">
            <input
              type="number"
              id="inputTelefono"
              name="telefono"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.telefono}
              className={formErrors.telefono == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.telefono == null || inputValues.telefono == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputTelefono"
            >
              Teléfono
            </label>
            {formErrors.telefono && <span>{formErrors.telefono}</span>}
          </div>
          {/* Input Código Postal */}
          <div className="input-container" id="input-codigo-postal">
            <input
              type="number"
              id="inputCodigoPostal"
              name="codigoPostal"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.codigoPostal}
              className={formErrors.codigoPostal == null ? "" : "input-error"}
            ></input>
            <label
              className={
                inputValues.codigoPostal == null ||
                inputValues.codigoPostal == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputCodigoPostal"
            >
              Cód. Postal
            </label>
            {formErrors.codigoPostal && <span>{formErrors.codigoPostal}</span>}
          </div>
          <a
            href="https://www.correoargentino.com.ar/formularios/cpa"
            target="_blank"
            id="codigo-postal-boton"
          >
            <span>
              <AiOutlineQuestionCircle></AiOutlineQuestionCircle>
            </span>
          </a>
        </div>
        {/* Datos de Pago Container */}
        <div className="datos-pago-container">
          <p className="titulo-formulario">
            ¡Lo último! Completá tu método de pago
          </p>
        </div>
        {/* Pago Transferencia Bancaria */}
        {inputValues.formaDePago == "Transferencia bancaria" && (
          <>
            {/* Select Forma de Pago */}
            <div className="select-container" id="select-forma-de-pago">
              <select
                id="selectFormaDePago"
                name="formaDePago"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.formaDePago}
                className={formErrors.formaDePago == null ? "" : "input-error"}
              >
                {/* Opción por predeterminado */}
                <option selected disabled></option>
                <option value="Tarjeta de credito">Tarjeta de credito</option>
                <option value="Transferencia bancaria">
                  Transferencia bancaria
                </option>
              </select>
              <div className="flecha-select-container">
                <i></i>
              </div>
              <label
                className={
                  inputValues.formaDePago == null ||
                  inputValues.formaDePago == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="selectFormaDePago"
              >
                Método
              </label>
              {formErrors.formaDePago && <span>{formErrors.formaDePago}</span>}
            </div>
            <div className="input-container" id="input-cbu">
              <input
                type="number"
                id="inputCBU"
                name="cbu"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.cbu}
                className={formErrors.cbu == null ? "" : "input-error"}
              ></input>
              <label
                className={
                  inputValues.cbu == null || inputValues.cbu == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="inputCBU"
              >
                CBU/CVU
              </label>
              {formErrors.cbu && <span>{formErrors.cbu}</span>}
            </div>
          </>
        )}
        {/* Pago Tarjeta Container */}
        {inputValues.formaDePago == "Tarjeta de credito" && (
          <div id="tarjeta-datos">
            {/* Select Forma de Pago */}
            <div className="select-container" id="select-forma-de-pago">
              <select
                id="selectFormaDePago"
                name="formaDePago"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.formaDePago}
                className={formErrors.formaDePago == null ? "" : "input-error"}
              >
                {/* Opción por predeterminado */}
                <option selected disabled></option>
                <option value="Tarjeta de credito">Tarjeta de credito</option>
                <option value="Transferencia bancaria">
                  Transferencia bancaria
                </option>
              </select>
              <div className="flecha-select-container">
                <i></i>
              </div>
              <label
                className={
                  inputValues.formaDePago == null ||
                  inputValues.formaDePago == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="selectFormaDePago"
              >
                Método
              </label>
              {formErrors.formaDePago && <span>{formErrors.formaDePago}</span>}
            </div>
            {/* Input Bnaco Emisor */}
            <div className="input-container" id="input-banco-emisor">
              <input
                type="text"
                id="inputBancoEmisor"
                name="bancoEmisor"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.bancoEmisor}
                className={formErrors.bancoEmisor == null ? "" : "input-error"}
              ></input>
              <label
                className={
                  inputValues.bancoEmisor == null ||
                  inputValues.bancoEmisor == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="inputBancoEmisor"
              >
                Banco emisor
              </label>
              {formErrors.bancoEmisor && <span>{formErrors.bancoEmisor}</span>}
            </div>
            {/* Input Número de tarjeta */}
            <div className="input-container" id="input-numero-tarjeta">
              <input
                type="number"
                id="inputNumeroTarjeta"
                name="numeroTarjeta"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.numeroTarjeta}
                className={
                  formErrors.numeroTarjeta == null ? "" : "input-error"
                }
              ></input>
              <label
                className={
                  inputValues.numeroTarjeta == null ||
                  inputValues.numeroTarjeta == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="inputNumeroTarjeta"
              >
                Número de tarjeta
              </label>
              {formErrors.numeroTarjeta && (
                <span>{formErrors.numeroTarjeta}</span>
              )}
            </div>
            {/* Input Titular */}
            <div className="input-container" id="input-titular">
              <input
                type="text"
                id="inputTitular"
                name="titular"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.titular}
                className={formErrors.titular == null ? "" : "input-error"}
              ></input>
              <label
                className={
                  inputValues.titular == null || inputValues.titular == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="inputTitular"
              >
                Titular
              </label>
              {formErrors.titular && <span>{formErrors.titular}</span>}
            </div>
            {/* Input DNI Titular */}
            <div className="input-container" id="input-dni-titular">
              <input
                type="number"
                id="inputDNITitular"
                name="dniTitular"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.dniTitular}
                className={formErrors.dniTitular == null ? "" : "input-error"}
              ></input>
              <label
                className={
                  inputValues.dniTitular == null || inputValues.dniTitular == ""
                    ? "label-dowmn"
                    : "label-top"
                }
                htmlFor="inputDNITitular"
              >
                DNI Titular
              </label>
              {formErrors.dniTitular && <span>{formErrors.dniTitular}</span>}
            </div>
            {/* Input Vencimiento */}
            <div className="input-container" id="input-vencimiento">
              <input
                type="month"
                // De esta manera no deja poner tarjetas vencidas y se updatea solo
                min={añoActual + "-" + mesActual}
                max={añoActual + 8 + "-" + mesActual}
                id="inputVencimiento"
                name="vencimiento"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.vencimiento}
                className={formErrors.vencimiento == null ? "" : "input-error"}
              ></input>
              <label
                className={
                  inputValues.vencimiento == null ||
                  inputValues.vencimiento == ""
                    ? "label-top"
                    : "label-top"
                }
                htmlFor="inputVencimiento"
              >
                Vencimiento
              </label>
              {formErrors.vencimiento && <span>{formErrors.vencimiento}</span>}
            </div>
          </div>
        )}
        {inputValues.formaDePago == "Tarjeta de credito" && (
          <div className="tarjeta-container">
            {/* Tarjeta */}
            <Tarjeta
              numero={inputValues.numeroTarjeta}
              titular={inputValues.titular}
              vencimiento={inputValues.vencimiento}
              cvc={inputValues.cvc}
              focused={inputValues.focus}
            />
          </div>
        )}
        {/* Botón Contratá Container */}
        <div id="boton-contrata-container">
          <button
            type="button"
            id="boton-contrata"
            onClick={handleValidation}
            disabled={loading}
          >
            {loading ? "Contratando..." : "¡Contratar ahora!"}
            <img
              alt="Ícono patita"
              src={process.env.PUBLIC_URL + "/pasos/contrata/patita.svg"}
            ></img>
          </button>
        </div>
      </form>
    </Container>
  );
}
