import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import Container from "./styled";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useFile } from "../../../hooks/useFile";
import { useLoading } from "../../../hooks/useLoading";
import { useScrollTop } from "../../../hooks/useScrollTop";
// Form
import { formaParteReglas } from "../../../utils/formRules";
import { formaParteForm } from "../../../utils/formInitialValues";
// Mail
import { formaParteMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";

export default function PasoUno({ pasoSiguiente }) {

  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors, handleChangeExternal } = useForm(handleSubmit, formaParteReglas, formaParteForm)
  const { loading, initiateLoading } = useLoading()
  const { onFileUpload, deleteFile, sendFiles } = useFile(handleChangeExternal)
  useScrollTop()

  async function handleSubmit() {
    try {
      initiateLoading()
      const filePath = await sendFiles(inputValues.cv);
      if (filePath) {
        await sendFormMail(inputValues, pasoSiguiente, formaParteMail, filePath)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      {/* Pop up Errores */}
      {Object.keys(formErrors).length > 0 ?
          <div id="popup-error">
            {Object.values(formErrors).map(errorMessage => {
              return (
                <p>
                  <span>
                    <BiError />
                  </span>
                  {errorMessage}
                </p>
              )
            })}
          </div>
        : 
          null
      }
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¡Formá parte de Gestión y Servicios!</h3>
          <p id="completa">
            Completá el siguiente formulario y envianos un mensaje con tus
            motivaciones, pronto nos comunicaremos para charlar con vos.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Puesto */}
              <div className="input-container" id="input-puesto-container">
                <input
                  type="text"
                  id="input-puesto"
                  name="puesto"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.puesto}
                  className={formErrors.puesto == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.puesto == null || inputValues.puesto == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-puesto"
                >
                  Puesto
                </label>
              </div>
              {/* Input Linkedin */}
              <div className="input-container" id="input-linkedin-container">
                <input
                  type="text"
                  id="input-linkedin"
                  name="linkedin"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.linkedin}
                  className={formErrors.linkedin == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.linkedin == null || inputValues.linkedin == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-linkedin"
                >
                  LinkedIn
                </label>
              </div>
            </div>
            <div id="mensaje-motivo">
              <h4>Contanos tus motivaciones</h4>
              {/* Textarea Mensaje */}
              <div className="input-container" id="textarea-mensaje-container">
                <textarea
                  type="text"
                  id="input-mensaje"
                  name="mensaje"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.mensaje ? inputValues.mensaje : ""}
                  className={formErrors.mensaje == null ? "" : "input-error"}
                ></textarea>
                <label
                  className={
                    inputValues.mensaje == null || inputValues.mensaje == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="input-mensaje"
                >
                  Ingresá tu mensaje
                </label>
              </div>
            </div>
            <div id="cv-container">
              <h4>Subí tu CV</h4>
              {/* Subir CV */}
              {inputValues.cv ? (
                <></>
              ) : (
                <div id="cv-input-container">
                  <label for="cv-input" id="cv-boton">
                    <span>
                      <BsFillFileEarmarkPdfFill></BsFillFileEarmarkPdfFill>
                    </span>
                    Seleccionar
                  </label>
                  <input
                    type="file"
                    name="cv"
                    // Acepta PDFs, archivos de Word y texto plano
                    accept="application/pdf"
                    id="cv-input"
                    onChange={(e) => onFileUpload(e)}
                  ></input>
                </div>
              )}
              {inputValues.cv ? (
                <div id="archivo-container">
                  <a
                    id="archivo-boton"
                    href={URL.createObjectURL(inputValues.cv.image)}
                    target="_blank"
                  >
                    <b>{inputValues.cv.name}</b>
                  </a>
                  <span id="borrar-boton" onClick={() => deleteFile("cv")}>
                    <FiTrash></FiTrash>
                  </span>
                </div>
              ) : null}
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={handleValidation} disabled={loading}>
                {loading ? "Enviando..." : "Enviar"}<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" style={style}>
                <p>Email</p>
                <p id="plazos-texto">rrhh@gyssrl.com.ar</p>
                <div>
                  <p>Ver más</p>
                  <Boton to="/contacto">Contacto</Boton>
                  <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                  <Boton to="/politicas-y-terminos">Políticas y términos</Boton>
                  <Boton to="/referidos">Referidos</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
