import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header/index";
import PasosSwitch from "../components/pasos/swtich";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function PasosContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Switch de pasos*/}
      <PasosSwitch></PasosSwitch>
    </Container>
  );
}
