import { BsArrowLeft } from "react-icons/bs";
import Container from "./styled";
import Marcador from "../marcador";
import NumberFormat from "react-number-format";
import { IoWarningOutline } from "react-icons/io5";
import { Fade } from "react-awesome-reveal";
import Plan from "./plan";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useQuestion } from "../../../hooks/useQuestion";
import { useTracking } from "../../../hooks/useTracking";
import { Link } from "react-router-dom";
//import planes from "../../../data/planes"

export default function Elegi({ nextStep, planSelected, planes, cotizacion }) {

  // Custom Hooks
  const { isQuestionOpen, handleToggle } = useQuestion()
  useScrollTop()
  //useTracking()

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno="hecho"
        pasoDos="haciendo"
        pasoTres=""
        pasosTerminados=""
        titulo="¡Cotizaste tu seguro con éxito!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad03">
        {/* Botón Atras */}
        <Link to="/">
          <span>
            <BsArrowLeft />
          </span>
          Volvé a cotizar
        </Link>
      </div>
      {/* Titulo Section */}
      <p className="titulo-section">¡Ahora elegí un plan a tu medida!</p>
      {/* Dropdowns Container */}
      <div className="dropdowns-container">
        {planes.map((plan, index) => {
            return (
              <Fade delay={50} duration={400} className="dropdown-container">
                <Plan
                  data={plan}
                  index={index}
                  active={isQuestionOpen}
                  handleToggle={handleToggle}
                  planSelected={planSelected}
                  nextStep={nextStep}
                  cotizacion={cotizacion}
                />
              </Fade>
            );
        })}
       
      </div>
    </Container>
  );
}
