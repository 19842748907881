export const razasGatos = [
    "Abisinio",
    "American Curl",
    "American Shorthair",
    "Angora Turco",
    "Azul Ruso",
    "Balinés",
    "Bengalí",
    "Bobtail Americano",
    "Bobtail Japonés",
    "Bombay",
    "British Shorthair",
    "Burmés",
    "Burmilla",
    "Chartreux",
    "Cornish Rex",
    "Devon Rex",
    "Egyptian Mau",
    "European Shorthair",
    "Exótico de Pelo Corto",
    "Fold Escocés",
    "Gato Común Europeo",
    "Gato Siamés",
    "Gato Sphynx",
    "Himalayo",
    "Korat",
    "Maine Coon",
    "Manx",
    "Mau Egipcio",
    "Munchkin",
    "Noruego de Bosque",
    "Ocicat",
    "Oriental",
    "Persa",
    "Peterbald",
    "Pixie Bob",
    "Ragdoll",
    "Sagrado de Birmania",
    "Savannah",
    "Scottish Fold",
    "Selkirk Rex",
    "Serengeti",
    "Siamés",
    "Siberiano",
    "Singapura",
    "Snowshoe",
    "Somalí",
    "Sphynx",
    "Tonkinés",
    "Van Turco",
];

export const razasPerros = [
    "Labrador Retriever",
    "Golden Retriever",
    "Bulldog",
    "Boxer",
    "Chihuahua",
    "Cocker Spaniel",
    "Dálmata",
    "Doberman",
    "Pastor Alemán",
    "Pit Bull",
    "Pug",
    "Rottweiler",
    "Schnauzer",
    "Setter Irlandés (Irish Setter)",
    "Shar Pei",
    "Yorkshire Terrier",
    "Akita Inu",
    "Bichón Frisé (Bichon Frise)",
    "Basset Hound",
    "Beagle",
    "Bernese Mountain Dog",
    "Border Collie",
    "Boston Terrier",
    "Bóxer",
    "Boyero de Berna (Bernese Mountain Dog)",
    "Bull Terrier",
    "Cairn Terrier",
    "Chow Chow",
    "Collie",
    "Dachshund",
    "Galgo (Greyhound)",
    "Husky Siberiano",
    "Jack Russell Terrier",
    "Mastín Napolitano",
    "Papillon",
    "Pequinés",
    "Perro de Agua Español (Spanish Water Dog)",
    "Perro Lobo (Wolf Dog)",
    "Pointer",
    "Presa Canario (Canary Mastiff)",
    "Schnauzer Gigante (Giant Schnauzer)",
    "Scottish Terrier",
    "Setter Inglés (English Setter)",
    "Shar Pei chino (Chinese Shar Pei)",
    "Shetland Sheepdog",
    "Staffordshire Bull Terrier",
    "Terrier Tibetano (Tibetan Terrier)",
    "Weimaraner",
    "Alaskan Malamute",
    "American Staffordshire Terrier",
    "Bichón Frisé",
    "Borzoi",
    "Bullmastiff",
    "Caniche",
    "Caniche Toy",
    "Cane Corso",
    "Doberman Pinscher",
    "Fila Brasileiro",
    "Fox Terrier",
    "Gran Danés",
    "Lebrel Afgano",
    "Lebrel Húngaro",
    "Lebrel Irlandés",
    "Lhasa Apso",
    "Mastín Español",
    "Mastín Tibetano",
    "Pekinés",
    "Perro de los Faraones",
    "Salchicha",
    "Rat Terrier",
    "Rhodesian Ridgeback",
    "Spinone Italiano",
    "Teckel",
    "Whippet",
];
  
  
  