import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Fade Container
  .fade-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Tag Container & Title Container
  .tag-container,
  .title-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Tag
    div {
      width: auto;
      padding: 8px 16px 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background-color: #f6f8fd;
      border-radius: 76px;
    }

    // Tag Text
    p {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: var(--lila90);
      line-height: 154.7%;
    }
  }

  // Title Container
  .title-container {
    margin: 32px 0px 0px 0px;
  }

  // Title
  .title-container h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 143.5%;

    // Span
    span {
      color: var(--naranja100);
    }
  }

  h4 {
    width: 100%;
    margin: 32px 0px 48px 0px;
    text-align: center;
  }

  // Información Referidos Container
  #informacion-referidos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Información Referidos Container Div
  #texto-container,
  #imagen-container {
    width: 397px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  // Texto Container
  #texto-container {
    flex-wrap: wrap !important;
    justify-content: flex-start;
  }

  // Imagen Container Imagen
  #imagen-container {
    height: 344px !important;
    margin: 0px 116px 0px 0px;
  }

  // Imagen Container Imagen
  #imagen-container div div {
    height: 344px !important;
  }

  // Texto Titulo
  #texto-titulo {
    width: 100%;
    margin: 0px 0px 24px 0px;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: var(--negro);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    line-height: 40px;
  }

  #texto-cliente,
  #texto-no-cliente {
    width: 100%;
    margin: 16px 0px 0px 0px;
    padding: 16px 24px 16px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
  }

  #texto-no-cliente {
    margin: 24px 0px 0px 0px;
  }

  #texto-container div h4 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    text-align: left;
    line-height: 27px;
    color: var(--lila90);
  }

  #texto-container div p {
    width: 100%;
    margin: 8px 0px 0px 0px;
    text-align: left;
    color: var(--negro);
    font-weight: 400;
    font-size: 15px;
    opacity: 0.9;
  }

  // Referir Botón
  #referir-boton {
    margin: 64px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    pointer-events: all !important;
  }

  // Referir Botón Hover
  #referir-boton:hover {
    background-color: var(--naranja40);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }

    // Texto Titulo
    #texto-titulo {
      width: 70%;
    }

    // Desplegable Container
    .desplegable-container {
      width: 85%;
    }

    // Desplegable Container Abierto
    .desplegable-container-abierto {
      width: 85%;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }

    // Información Referidos Container
    #informacion-referidos-container {
      flex-wrap: wrap;
      justify-content: center;
    }

    // Información Referidos Container Div
    #texto-container,
    #imagen-container {
      width: 80%;
      margin: 0px 0px 0px 0px;
      justify-content: center;
    }

    #texto-container {
      margin: 40px 0px 0px 0px;
    }

    #texto-container div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
    }

    // Texto Titulo
    #texto-titulo {
      text-align: center;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }

    // Texto Titulo
    #texto-titulo {
      width: 100%;
    }

    // Desplegable Container
    .desplegable-container {
      width: 100%;
    }

    // Desplegable Container Abierto
    .desplegable-container-abierto {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 90%;
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Title
    h3 {
      margin: 0px 0px 0px 0px;
    }

    // Imagen Container Imagen
    #imagen-container {
      display: none;
    }

    // Texto Titulo
    #texto-titulo {
      display: none;
    }

    #texto-container {
      margin: 32px 0px 0px 0px;
    }
  }
`;

export default Container;
