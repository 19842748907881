import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
// Container
import Container from "./styled";
// Iconos
import { BsWhatsapp } from "react-icons/bs";
// Librerias
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
// Animaciones
import Animacion from "./animacion";
import AnimacionCarga from "./animacion-carga";
// Arrays de razas
import { razasGatos, razasPerros } from "../../constants/razas";
// Custom Hooks
import { useCotizador } from "../../hooks/useCotizador";
import { useForm } from "../../hooks/useForm";
// Form
import { cotizadorReglas } from "../../utils/formRules";
import { cotizadorForm } from "../../utils/formInitialValues";
// Params
import { PARAMS } from "../../constants/params";
// Servicios
import { postearOperacion, postearCotizacion } from "../../services/cotizador";

export default function Cotizador({ landing, isReferidosHeaderOpen }) {
  // Estado para el loading
  const [loading, setLoading] = useState(false);

  // Params provenientes de la URL
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate()

  // Custom Hooks
  const { inputValues, handleChange, handleChangeExternal, handleChangeCheckbox, handleValidation, formErrors } = useForm(handleSubmit, cotizadorReglas, cotizadorForm)
  const { ip, canal_url, razaOpen, toggleRaza, referencia, textareaRaza } = useCotizador(inputValues)


    // Enviar mail
    async function handleSubmit () {
      setLoading(true);
      const origen = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? "mobile" : "web";
  
      const urlParams = {}
      PARAMS.forEach(param => urlParams[param] = searchParams.get(param))
  
      const { telefono, codigoArea, codigoPostal, ...cotizadorValues } = inputValues
  
      const dataOperacion = {
        ...cotizadorValues,
        telefono: `(${codigoArea}) ${telefono}`,
        codigo_postal: codigoPostal,
        ip_adress: ip,
        autogestion: 0,
        origen: origen,
        canal_url: canal_url,
        ...urlParams
      };
      
      console.log(dataOperacion)
      try {
        const postOperacion = await postearOperacion(dataOperacion)
        const cotizacionTablaData = {
          id_detalle_prod_mascotas: postOperacion.data.id,
          id_operacion: postOperacion.data.numero,
        }
        const postCotizacion = await postearCotizacion(cotizacionTablaData)
        if (postOperacion.status === 200 && postCotizacion.status === 200) {
          navigate(`/pasos/?op=${postOperacion.data.numero}`, { state: postOperacion.data })
        }  
      } catch (error) {
        console.log(error);
      } 
    };

  return (
    <Container
      name="cotizador"
      isReferidosHeaderOpen={isReferidosHeaderOpen}
      razaOpen={razaOpen}
      mestizo={inputValues.mestizo}
    >
      {/* Formulario Container */}
      <div id="form-titulo-container" className="opacidad05">
        {/* Titulos */}
        <div id="titulos-logo-container">
          <div id="titulos-container">
            {/* Titulo */}
            <h1 className="opacidad05">¡Protegé tu mascota!</h1>
            <h3 className="opacidad05">
              {landing != "federacion"
                ? "Contratá el seguro ideal para tu mascota."
                : "Cuidá a tu mascota con Federación Patronal."}
            </h3>
          </div>
        </div>
        {/* Formulario Container */}
        <div id="form-container" className="opacidad05">
          {/* Formulario */}
          <form>
            {/* Inputs Container */}
            <div id="inputs-container">
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Codigo de Area */}
              <div className="input-container" id="input-codigo-area-container">
                <input
                  type="number"
                  name="codigoArea"
                  id="input-codigo-area"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoArea}
                  className={formErrors.codigoArea == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoArea == null ||
                    inputValues.codigoArea == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-area"
                >
                  Cod. Área
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
              {/* Provincia*/}
              <div className="select-container" id="select-provincia-container">
                <select
                  id="select-provincia"
                  name="provincia"
                  value={inputValues.provincia}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.provincia == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  <option value="Buenos Aires">Buenos Aires</option>
                  <option value="Catamarca">Catamarca</option>
                  <option value="Chaco">Chaco</option>
                  <option value="Chubut">Chubut</option>
                  <option value="Ciudad de Buenos Aires">
                    Ciudad de Buenos Aires
                  </option>
                  <option value="Córdoba">Córdoba</option>
                  <option value="Corrientes">Corrientes</option>
                  <option value="Entre Rios">Entre Rios</option>
                  <option value="Formosa">Formosa</option>
                  <option value="Jujuy">Jujuy</option>
                  <option value="La Pampa">La Pampa</option>
                  <option value="La Rioja">La Rioja</option>
                  <option value="Mendoza">Mendoza</option>
                  <option value="Misiones">Misiones</option>
                  <option value="Neuquén">Neuquén</option>
                  <option value="Rio Negro">Rio Negro</option>
                  <option value="Salta">Salta</option>
                  <option value="San Juan">San Juan</option>
                  <option value="San Luis">San Luis</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Santa Fe">Santa Fe</option>
                  <option value="Santiago del Estero">
                    Santiago del Estero
                  </option>
                  <option value="Tierra del Fuego">Tierra del Fuego</option>
                  <option value="Tucumán">Tucumán</option>
                </select>
                <label
                  className={
                    inputValues.provincia == null || inputValues.provincia == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-provincia"
                >
                  Provincia
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Input Código postal */}
              <div className="input-container" id="input-codigo-postal-container">
                <input
                  type="text"
                  name="codigoPostal"
                  id="input-codigo-postal"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoPostal}
                  className={formErrors.codigoPostal == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoPostal == null ||
                    inputValues.codigoPostal == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-postal"
                >
                  Cod. Postal
                </label>
              </div>
              {/* Select Mascota */}
              <div className="select-container" id="select-mascota-container">
                <select
                  id="select-mascota"
                  name="mascota"
                  value={inputValues.mascota}
                  onChange={(e) => handleChange(e, true)}
                  onFocus={(e) => handleChange(e, true)}
                  onBlur={(e) => handleChange(e, true)}
                  className={formErrors.mascota == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  <option value="Perro">Perro</option>
                  <option value="Gato">Gato</option>
                </select>
                <label
                  className={
                    inputValues.mascota == null || inputValues.mascota == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-mascota"
                >
                  Mascota
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Input Edad */}
              <div className="input-container" id="input-edad-container">
                <input
                  type="number"
                  id="input-edad"
                  name="edad"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.edad}
                  className={formErrors.edad == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.edad == null || inputValues.edad == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-edad"
                >
                  Edad
                </label>
              </div>
              {/* Textarea Raza */}
              <div
                className="input-container"
                id="input-raza-container"
                ref={referencia}
              >
                <TextInput
                  trigger={[""]}
                  options={{
                    "":
                      inputValues.mascota == "Perro" ? razasPerros : razasGatos,
                  }}
                  id="input-raza"
                  onChange={(valor) => {
                    handleChangeExternal("raza", valor);
                  }}
                  class={formErrors.raza == null ? "" : "input-error"}
                  spacer=""
                  onClick={toggleRaza}
                  ref={textareaRaza}
                  Component="input"
                  value={inputValues.raza}
                />
                <label
                  className={
                    inputValues.raza == null || inputValues.raza == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-raza"
                  id="label-raza"
                >
                  Raza
                </label>
              </div>
              {/* Checkboc Mestizo */}
              <label className="check-container" id="check-mestizo-container">
                <p>Mi mascota es mestiza (sin raza)</p>
                <input
                  type="checkbox"
                  id="check-mestizo"
                  name="mestizo"
                  value="Mestizo (sin raza)"
                  checked={inputValues.mestizo}
                  onChange={handleChangeCheckbox}
                />
                <span id="checkmark"></span>
              </label>
            </div>
            {/* Botones Container */}
            <div id="botones-container">
              {/* Whatsapp Button */}
              <Tippy content="Días hábiles de 9 a 18hs" animation="scale">
                <a
                  href="https://api.whatsapp.com/send?phone=5491162255399&text=%C2%A1Hola%2C%20necesito%20ayuda%20para%20cotizar%20mi%20mascota!%20Ingresé%20desde%20la%20web"
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  Necesito ayuda
                </a>
              </Tippy>
              {/* Cotizar Button */}
              <button
                type="submit"
                onClick={handleValidation}
                className="submit-button"
              >
                ¡Cotizá tu seguro!
                <img
                  alt="Ícono mascota"
                  src={process.env.PUBLIC_URL + "/cotizador/perro.svg"}
                ></img>
              </button>
            </div>
            {/* Loading Container */}
            {loading ? (
              <div id="loading-container" className="opacidad03">
                <div id="animacion-loading">
                  <AnimacionCarga></AnimacionCarga>
                </div>
              </div>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
      {/* Animacion Container */}
      <div id="animacion-container" className="opacidad08">
        {/* Animacion */}
        <Animacion></Animacion>
      </div>
    </Container>
  );
}
