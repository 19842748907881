export const cotizadorForm = {
  nombre:
    localStorage.getItem("nombre-seguromascotas") !== null
      ? localStorage.getItem("nombre-seguromascotas")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromascotas") !== null
      ? localStorage.getItem("apellido-seguromascotas")
      : "",
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  codigoArea:
    localStorage.getItem("codigoArea-seguromascotas") !== null
      ? localStorage.getItem("codigoArea-seguromascotas")
      : "",
  telefono:
    localStorage.getItem("telefono-seguromascotas") !== null
      ? localStorage.getItem("telefono-seguromascotas")
      : "",
  provincia:
    localStorage.getItem("provincia-seguromascotas") !== null
      ? localStorage.getItem("provincia-seguromascotas")
      : "",
  codigoPostal:
  localStorage.getItem("codigoPostal-seguromascotas") !== null
    ? localStorage.getItem("codigoPostal-seguromascotas")
    : "",
  mestizo: false,
  mascota:
    localStorage.getItem("mascota-seguromascotas") !== null
        ? localStorage.getItem("mascota-seguromascotas")
        : "",
  edad:
    localStorage.getItem("edad-seguromascotas") !== null
        ? localStorage.getItem("edad-seguromascotas")
        : "",
  raza: '',
};

export const referidosForm = {
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  emailReferido: "",
  nombreReferido: "",
  captcha: undefined
}

export const arrepentimientoForm = {
  nombre:
    localStorage.getItem("nombre-seguromascotas") !== null
      ? localStorage.getItem("nombre-seguromascotas")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromascotas") !== null
      ? localStorage.getItem("apellido-seguromascotas")
      : "",
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguromascotas") !== null
      ? localStorage.getItem("telefono-seguromascotas")
      : "",
  mensaje: '',
  captcha: undefined
}

export const bajaServicioForm = {
  nombre:
    localStorage.getItem("nombre-seguromascotas") !== null
      ? localStorage.getItem("nombre-seguromascotas")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromascotas") !== null
      ? localStorage.getItem("apellido-seguromascotas")
      : "",
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguromascotas") !== null
      ? localStorage.getItem("telefono-seguromascotas")
      : "",
  mensaje: '',
  captcha: undefined
}

export const contactoForm = {
  nombre:
  localStorage.getItem("nombre-seguromascotas") !== null
    ? localStorage.getItem("nombre-seguromascotas")
    : "",
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  telefono:
    localStorage.getItem("telefono-seguromascotas") !== null
      ? localStorage.getItem("telefono-seguromascotas")
      : "",
  asunto: '',
  mensaje: '',
  captcha: undefined
}

export const formaParteForm = {
  nombre:
    localStorage.getItem("nombre-seguromascotas") !== null
    ? localStorage.getItem("nombre-seguromascotas")
    : "",
  apellido:
    localStorage.getItem("apellido-seguromascotas") !== null
      ? localStorage.getItem("apellido-seguromascotas")
      : "",
  email:
    localStorage.getItem("email-seguromascotas") !== null
      ? localStorage.getItem("email-seguromascotas")
      : "",
  puesto: '',
  mensaje: '',
  cv: '',
  captcha: undefined
}

// *** Contratá *** //
export const contrataForm = {
  nombre: localStorage.getItem("nombre-seguromascotas") ? localStorage.getItem("nombre-seguromascotas") : "",
  apellido: localStorage.getItem("apellido-seguromascotas") ? localStorage.getItem("apellido-seguromascotas") : "",
  email: localStorage.getItem("email-seguromascotas") ? localStorage.getItem("email-seguromascotas") : "",
  codigoArea: localStorage.getItem("codigo-area-seguromascotas") ? localStorage.getItem("codigo-area-seguromascotas") : "",
  telefono: localStorage.getItem("telefono-seguromascotas") ? localStorage.getItem("telefono-seguromascotas") : "",
  // Datos forma de pago
  formaDePago: "Tarjeta de credito",
  numeroTarjeta: "",
  bancoEmisor: "",
  vencimiento: "",
  titular: "",
  cuitDNI: "",
  codigoPostal: "",
  cvc: "",
  cbu: "",
  dniTitular: "",
  tipo_tarjeta: "",
}