import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--grisClaro);

  // Fade Container
  .fade-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Coberturas Container
  .coberturas-container {
    width: 1000px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Titulo
  .coberturas-container h2 {
    width: 100%;
    margin: 64px 0px 80px 0px;
    font-size: 27px;
    text-align: center;
  }

  .coberturas-container h2 span {
    color: var(--naranja100);
  }

  // Columna
  .columna {
    width: 33.3%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  // Tag
  .tag {
    min-width: 177px;
    padding: 4px 0px 4px 0px;
    margin: 0px 12px 40px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-image: linear-gradient(
      45deg,
      rgba(119, 148, 229, 0.07) 0%,
      rgba(119, 148, 229, 0.07) 40%,
      rgba(150, 168, 255, 0.15) 50%,
      rgba(150, 168, 255, 0.15) 55%,
      rgba(119, 148, 229, 0.07) 65%,
      rgba(119, 148, 229, 0.07) 100%
    );
    background-size: 230%;
    animation: 3000ms ease 0s infinite normal none running shimmer;
    border: 1px solid var(--grisClaro);
    border-radius: 8px;
  }

  // Shimmer Animación
  @keyframes shimmer {
    0% {
      background-position: -30% -30%;
    }
    100% {
      background-position: 160% 160%;
    }
  }

  // Tag Texto
  .tag p {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
    color: var(--lila90);
    text-align: center;
  }

  // Tag Icon
  .tag p span {
    margin: 6px 10px 0px 0px;
    font-size: 1.7em;
    color: var(--verde50);
  }

  // Line Break
  .line-break {
    width: 100%;
  }

  // Botón Cotizar
  a {
    margin: 40px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Botón Cotizar Hover
  a:hover {
    background-color: var(--naranja40);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    .coberturas-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Coberturas Container
    .coberturas-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    display: none;
  }
`;

export default Container;
