import Marcador from "../marcador/index";
import { BsCheckLg } from "react-icons/bs";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
import NumberFormat from "react-number-format";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useConsulta } from "../../../hooks/useConsulta";

export default function Contrataste({ planSelected, cotizacion }) {
  useScrollTop();
  useConsulta(planSelected, cotizacion);

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno=""
        pasoDos=""
        pasoTres=""
        pasosTerminados={true}
        titulo={
          planSelected.precio
            ? `¡${cotizacion.nombre}, contrataste tu seguro!`
            : `¡${cotizacion.nombre}, consultaste con exito!`
        }
        pasoTresTexto="Consultá"
      ></Marcador>
      {/* Sonido */}
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={Sound.status.PLAYING}
        volume={80}
      />
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <span></span>
          <Animacion></Animacion>
        </div>
        {/* Texto Agradecimiento */}
        <p id="texto-contacto">
          {planSelected.precio
            ? "Un ejecutivo se contactará con vos en las próximas horas para finalizar la contratación."
            : "Un ejecutivo de ventas se contactará con vos en la brevedad para continuar con el proceso."}
          <br />
          ¡Gracias por cotizar con SeguroMascotas!
        </p>
        {/* Max Width Coberturas Container */}
        <div id="max-width-coberturas-container">
          {/* Coberturas Container */}
          <div className="coberturas-container opacidad02">
            {/* Logo y Plan Container */}
            <div className="logo-plan-container">
              <img alt="Logo Seguro" src={planSelected.logo}></img>
              <p>{planSelected.plan}</p>
            </div>
            {/* Checks Container */}
            <div className="checks-container">
              {planSelected.coberturas.map((cobertura) => {
                return (
                  <span>
                    {cobertura.activo ? (
                      <span class="material-symbols-outlined">done</span>
                    ) : (
                      <span id="check-no" class="material-symbols-outlined">
                        close
                      </span>
                    )}
                    <p>{cobertura.cobertura_info.nombre}</p>
                  </span>
                );
              })}
            </div>
            {/* Información Vehiculo */}
            <div
              className="informacion-vehiculo"
              id="informacion-vehiculo-mobile"
            >
              <p>Golden Retriver</p>
              <p>10 años</p>
              <img
                src="/pasos/contrata/escudo.svg"
                alt="Escudo de SeguroMotos"
              ></img>
            </div>
            {/* Precios Container
              Aca cambiar una vez se reciba la data
              <div className="precio-container">
                Cuotas Container
                <div>
                  {this.props.planSelected.cuotas == "" ? null : (
                    <p
                      id={this.props.planSelected.precio == "" && "cuotas-bold"}
                      className={
                        this.props.planSelected.precioAnterior == "" &&
                        "cuotas-naranja"
                      }
                    >
                      {this.props.planSelected.cuotas}
                    </p>
                  )}
                  {this.props.planSelected.precioAnterior == "" ? null : (
                    <p id="precio-anterior">
                      {this.props.planSelected.precioAnterior}
                    </p>
                  )}
                </div>
                Precio
                {this.props.planSelected.precio == "" ? null : (
                  <div>
                    {this.props.planSelected.oferta == "" ? null : (
                      <p>{this.props.planSelected.oferta}</p>
                    )}
                    <p>{this.props.planSelected.precio}</p>
                  </div>
                )}
                {this.props.planSelected.contratando == "" ? null : (
                  <div>
                    <p>{this.props.planSelected.contratando}</p>
                  </div>
                )}
              </div> */}
            {/* Precios Container */}
            <div className="precio-container">
              {planSelected.precio && (
                <div>
                  <p id="cuotas-bold">Cuotas de</p>
                  <p id="precio-anterior"></p>
                </div>
              )}
              <div>
                {planSelected.precio ? (
                  <p>${planSelected.precio}</p>
                ) : (
                  <p>Consulta</p>
                )}
              </div>
              {planSelected.precio && (
                <div>
                  <p>Contratando Online</p>
                </div>
              )}
            </div>
          </div>
          {/* Datos Container */}
          <div id="datos-container">
            {/* Datos Mascota */}
            <div className="datos-vehiculo">
              {cotizacion.mascota == "Perro" ? (
                <img alt="Ícono de perro" src="/pasos/contrata/perro.svg"></img>
              ) : (
                <img alt="Ícono de gato" src="/pasos/contrata/gato.svg"></img>
              )}
              <p>{cotizacion.mascota}</p>
            </div>
            {/* Información Vehiculo */}
            <div className="informacion-vehiculo">
              <p>{cotizacion.raza}</p>
              <p>{cotizacion.edad} años</p>
              <img
                src="/pasos/contrata/escudo.svg"
                alt="Escudo de SeguroMascotas"
              ></img>
            </div>
            {/* Datos Cotizacion */}
            <div id="datos-cotizacion">
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>Provincia</p>
                <p>{cotizacion.provincia}</p>
              </div>
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>{planSelected.nombre_compania}</p>
                <p>Plan {planSelected.plan}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Datos Preparados 
        <div className="datos-preparados">
          <h4>
            Para agilizar el proceso,
            <span>tené listos los siguientes datos</span>:
          </h4>
          <div>
            <p>Aca faltan estos datos, habría que pedirlos</p>
          </div>
          <div>
            <p>Aca faltan estos datos, habría que pedirlos</p>
          </div>
          <div>
            <p>Aca faltan estos datos, habría que pedirlos</p>
          </div>
        </div>
        */}
      </div>
    </Container>
  );
}
