import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";
import { Link } from "react-scroll";
import "material-symbols";
import { BsCheckLg } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { VscCircleFilled } from "react-icons/vsc";

export default function Plan({
  data,
  active,
  index,
  handleToggle,
  planSelected,
  nextStep,
  cotizacion,
  landing,
}) {
  // Continuar al siguiente paso
  const Continue = (e, plan) => {
    e.preventDefault();
    planSelected(plan, cotizacion);
    if (plan.precio) {
      nextStep();
    } else {
      const ULTIMO_PASO = 3;
      nextStep(ULTIMO_PASO);
    }
  };

  return (
    <Container
      mejorPrecio={data?.mejorPrecio}
      recomendado={data?.recomendado}
      seguro={data?.nombre_compania}
    >
      {/* Dropdown */}
      <button
        className={data?.isActive ? "dropdown-abierto" : "dropdown-cerrado"}
        onClick={data?.clickFunction}
      >
        {data?.mejorPrecio && (
          <div className="mejor-precio shimmerPrecio">
            <p>
              <span>
                <FaMoneyBillWave></FaMoneyBillWave>
              </span>
              Mejor precio
            </p>
          </div>
        )}
        {data?.recomendado && (
          <div className="recomendado shimmerRecomendado">
            <p>
              <span>
                <AiFillLike></AiFillLike>
              </span>
              Recomendado
            </p>
          </div>
        )}
        {/* Logo and Plan Container */}
        <div className="logo-plan-container">
          <img alt="Logo Seguro" src={data.logo}></img>
          <p>{data.plan}</p>
        </div>
        {/* Checks Container */}
        <div className="checks-container">
          {/* Map para cada check */}
          {data.coberturas.map((cobertura) => {
            return (
              <span className="check">
                <p>{cobertura.cobertura_info?.nombre}</p>
                {cobertura.activo ? (
                  <span class="material-symbols-outlined">done</span>
                ) : (
                  <span id="check-no" class="material-symbols-outlined">
                    close
                  </span>
                )}
              </span>
            );
          })}
        </div>
        {/* Precios Container  */}
        <div className="precio-container">
          <div>
            {data.cuotas == null ? null : (
              <p
                id={data.precio == "" && "cuotas-bold"}
                className={data.precioAnterior == "" && "cuotas-naranja"}
              >
                12 cuotas
              </p>
            )}
            {data.precioAnterior == "" ? null : (
              <p id="precio-anterior">{data.precioAnterior}</p>
            )}
          </div>
          {data.precio == null ? null : (
            <div>
              {data.oferta == "" ? null : <p>{data.oferta}</p>}
              <p>Desde ${data.precio}</p>
            </div>
          )}
          {data.contratando == "" ? null : (
            <div>
              <p>{data.contratando}</p>
            </div>
          )}
        </div>
        {/* Botones Container */}
        <div className="botones-container">
          {
            !data.precio
            ?
            <Link
                id="contrata-boton"
                className="btn-big"
                onClick={(e) => Continue(e, data)}
                to="header"
                spy={true}
            >
                Te contactamos
            </Link>
              :
            <Link
              id="contrata-boton"
              onClick={(e) => Continue(e, data)}
              to="header"
              spy={true}
            >
              Contratar
            </Link>
          }
          <button id="ver-mas-boton" onClick={() => handleToggle(index)}>
            {active === index ? "Cerrar cobertura" : "Ver cobertura"}
          </button>
        </div>
      </button>
      {/* Contenido */}
      <Collapse isOpened={active === index}>
        <div className="children-container">
          {/* Datos Container */}
          <div className="datos-container">
            {/* Plan Container */}
            <div className="plan-container">
              <p>{data.nombre_compania}</p>
              <p>-</p>
              <p>{data.plan}</p>
            </div>
            {/* Detalles Beneficios Container */}
            <div className="detalles-beneficios-container">
              <div
                className="detalles-container"
                id={data.beneficios == "" && "centrado"}
              >
                <h4>
                  <span>
                    <BsCheckLg />
                  </span>
                  Detalles de cobertura
                </h4>
                {data.detalles.length &&
                  data.detalles.map((detalle) => {
                    return (
                      <div className="detalle">
                        <p>
                          <span>
                            <VscCircleFilled />
                          </span>
                          {detalle.descripcion}
                        </p>
                      </div>
                    );
                  })}
              </div>
              {/* Beneficios Container */}
              <h4
                id={
                  data.beneficios == "" ? "none-beneficios" : "beneficios-title"
                }
              >
                ¡Y muchos otros beneficios!
              </h4>
              <div
                className="beneficios-container"
                id={data.beneficios == "" && "none-beneficios"}
              >
                {data.beneficios.length &&
                  data.beneficios.map((beneficio) => {
                    return (
                      <div className="beneficio">
                        <p>
                          <span>
                            <VscCircleFilled />
                          </span>
                          {beneficio.descripcion}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Vigencia Container */}
          {
              !data.precio  
              ?  
              <div className="vigencia-container">
                <p>Vigencia Anual - Consultar precio</p>
                <Link onClick={(e) => Continue(e, data)} to="header" spy={true}>
                  Te contactamos
                </Link>
              </div>
              :
              <div className="vigencia-container">
                <p>Vigencia Anual - Contratar</p>
                <Link onClick={(e) => Continue(e, data)} to="header" spy={true}>
                  Contratar
                </Link>
              </div>
            }
        </div>
      </Collapse>
    </Container>
  );
}
