import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import Container from "./styled";
import { BsWhatsapp } from "react-icons/bs";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useLoading } from "../../../hooks/useLoading";
// Form
import { referidosReglas } from "../../../utils/formRules";
import { referidosForm } from "../../../utils/formInitialValues";
// Mail
import { referidosMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";

export default function Formulario({ pasoSiguiente }) {

  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors, handleChangeExternal } = useForm(handleSubmit, referidosReglas, referidosForm)
  const { loading, initiateLoading } = useLoading()

  async function handleSubmit() {
    try {
      initiateLoading()
      await sendFormMail(inputValues, pasoSiguiente, referidosMail)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      {/* Pop up Errores */}
      {Object.keys(formErrors).length > 0 ?
          <div id="popup-error">
            {Object.values(formErrors).map(errorMessage => {
              return (
                <p>
                  <span>
                    <BiError />
                  </span>
                  {errorMessage}
                </p>
              )
            })}
          </div>
        : 
          null
      }
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¡Aprovechá nuestro programa de referidos!</h3>
          <p id="completa">
            ¿Sos cliente?:
            <br />
            ¡Referí y gana $4000! Contactate a nuestro{" "}
            <a href="https://api.whatsapp.com/send?phone=5491162255399&text=Hola%20%F0%9F%98%80%2C%20soy%20cliente%20y%20quiero%20referir!" target="_blank">
              <span>
                <BsWhatsapp />
              </span>{" "}
              WhatsApp
            </a>{" "}
            de referidos.
            <br />
            <br />
            ¿No sos cliente?:
            <br />
            ¡Tenés un 20% OFF en el seguro que quieras! Dejanos tu email para
            obtener este beneficio.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
            </div>
            <div id="datos-referido">
              <h4>Datos del referido</h4>
              {/* Input Nombre Referido*/}
              <div
                className="input-container"
                id="input-nombre-referido-container"
              >
                <input
                  type="text"
                  id="input-nombre-referido"
                  name="nombreReferido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombreReferido}
                  className={
                    formErrors.nombreReferido == null ? "" : "input-error"
                  }
                ></input>
                <label
                  className={
                    inputValues.nombreReferido == null ||
                    inputValues.nombreReferido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre-referido"
                >
                  Nombre
                </label>
              </div>
              {/* Input Email Referido */}
              <div
                className="input-container"
                id="input-email-referido-container"
              >
                <input
                  type="email"
                  id="input-email-referido"
                  name="emailReferido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.emailReferido}
                  className={
                    formErrors.emailReferido == null ? "" : "input-error"
                  }
                ></input>
                <label
                  className={
                    inputValues.emailReferido == null ||
                    inputValues.emailReferido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email-referido"
                >
                  Email
                </label>
              </div>
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={handleValidation} disabled={loading}>
                {loading ? "Enviando..." : "Enviar"}<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" className="opacidad05" style={style}>
                <p>Ver más</p>
                <Boton to="/contacto">Contacto</Boton>
                <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                <Boton to="/politicas-y-terminos">Políticas y términos</Boton>
                <Boton to="/forma-parte">Forma parte</Boton>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
